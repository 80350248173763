<template>
  <div v-if="showTable">
    <div class="mb-6 overflow-x-auto border rounded-lg border-gray-light">
      <table class="w-full" aria-describedby="scoringLog">
        <template>
          <thead>
            <tr>
              <th scope="col" colspan="6" class="p-2 text-left">
                <p class="text-sm font-medium text-gray">{{activeScoring === vendorText? 'Scoring Technical Approved By' : 'Scoring Escrow Approved By'}}</p>
              </th>
            </tr>
            <tr class="bg-primary-lightest">
              <th v-if="approvalGsit?.userName" :colspan="colspan" scope="col" class="p-2 border-t border-gray-light" :class="`${threeColumn? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th v-if="approvalUkkp?.userName" :colspan="colspan" scope="col" class="p-2 border-t border-gray-light" :class="`${threeColumn? 'w-1/3 border-l' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
              <th scope="col" :colspan="colspan" class="p-2 border-t border-gray-light border-l" :class="`${threeColumn? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="approvalGsit?.userId" class="p-2 border-gray-light" :colspan="colspan" :class="`${threeColumn? 'w-1/3' : 'w-1/2'} ${showScoringPriceLog? 'border-b' : ''}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ approvalGsit?.userName}}</p>
                <p v-if="approvalGsit?.approvalStatusLocale?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ approvalGsit?.approvalStatusLocale?.toLowerCase() }} </p>
                <p v-else-if="['approved','rejected'].includes(approvalGsit?.approvalStatusLocale?.toLowerCase())"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(approvalGsit?.approvalStatusLocale)" class="capitalize">{{ approvalGsit?.approvalStatusLocale?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ approvalGsit?.approvalDateLocale }}</span> 
                </p>
              </td>
              <td v-if="approvalUkkp?.userId" class="p-2 border-gray-light" :colspan="colspan" :class="`${threeColumn? 'w-1/3 border-l' : 'w-1/2'} ${showScoringPriceLog? 'border-b' : ''}`" >
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ approvalUkkp?.userName}}</p>
                <p v-if="approvalUkkp?.approvalStatusLocale?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ approvalUkkp?.approvalStatusLocale?.toLowerCase() }}</p>
                <p v-else-if="['approved','rejected'].includes(approvalUkkp?.approvalStatusLocale?.toLowerCase())"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(approvalUkkp?.approvalStatusLocale)" class="capitalize">{{ approvalUkkp?.approvalStatusLocale?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ approvalUkkp?.approvalDateLocale }}</span>
                </p>
              </td>
              <td class="p-2 border-gray-light border-l" :colspan="colspan" :class="`${threeColumn? 'w-1/3' : 'w-1/2'} ${showScoringPriceLog? 'border-b' : ''}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ approvalDlog?.userName}}</p>
                <p v-if="approvalDlog?.approvalStatusLocale?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ approvalDlog?.approvalStatusLocale?.toLowerCase() }}</p>
                <p v-else-if="['approved','rejected'].includes(approvalDlog?.approvalStatusLocale?.toLowerCase())"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(approvalDlog?.approvalStatusLocale)" class="capitalize">{{ approvalDlog?.approvalStatusLocale?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span> 
                  <span class="whitespace-nowrap">{{ approvalDlog?.approvalDateLocale }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-if="showScoringPriceLog">
          <thead>
            <tr>
              <th scope="col" colspan="6" class="p-2 text-left">
                <p class="text-sm font-medium text-gray">Scoring Price Approved By</p>
              </th>
            </tr>
            <tr class="bg-primary-lightest">
              <th scope="col" :colspan="colspanPrice" class="p-2 border-t border-gray-light" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
              <th v-if="scoringPriceLogApproval?.gsitId" :colspan="colspanPrice" scope="col" class="p-2 border-t border-gray-light border-l" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th  v-if="scoringPriceLogApproval?.ukkpId" :colspan="colspanPrice" scope="col" class="p-2 border-t border-gray-light border-l" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td class="p-2 border-gray-light" :colspan="colspanPrice" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ scoringPriceLogApproval?.itpName }}</p>
                <p v-if="scoringPriceLogApproval?.isNeedApprovalItp && scoringPriceLogApproval?.itpApproved?.toLowerCase().includes('waiting')" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ scoringPriceLogApproval?.itpApproved }}</p>
                <p v-else-if="scoringPriceLogApproval?.itpApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(scoringPriceLogApproval?.itpApproved)">{{ scoringPriceLogApproval?.itpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ scoringPriceLogApproval?.itpApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td v-if="scoringPriceLogApproval?.gsitId" :colspan="colspanPrice" class="p-2 border-gray-light border-l" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{scoringPriceLogApproval?.gsitName}}</p>
                <p v-if="scoringPriceLogApproval?.isNeedApprovalGsit && scoringPriceLogApproval?.gsitApproved?.toLowerCase().includes('waiting')" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{scoringPriceLogApproval?.gsitApproved}}</p>
                <p v-else-if="scoringPriceLogApproval?.gsitApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(scoringPriceLogApproval?.gsitApproved)">{{scoringPriceLogApproval?.gsitApproved?.split('|')[0]}}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{scoringPriceLogApproval?.gsitApproved?.split('|')[1]}}</span>
                </p>
              </td>
              <td v-if="scoringPriceLogApproval?.ukkpId" :colspan="colspanPrice" class="p-2 border-gray-light border-l" :class="`${oneColumnPrice? 'w-full' : threeColumnPrice? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{scoringPriceLogApproval?.ukkpName}}</p>
                <p v-if="scoringPriceLogApproval?.isNeedApprovalUkkp && scoringPriceLogApproval?.ukkpApproved?.toLowerCase().includes('waiting')" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{scoringPriceLogApproval?.ukkpApproved}}</p>
                <p v-else-if="scoringPriceLogApproval?.ukkpApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(scoringPriceLogApproval?.ukkpApproved)">{{scoringPriceLogApproval?.ukkpApproved?.split('|')[0]}}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{scoringPriceLogApproval?.ukkpApproved?.split('|')[1]}}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableApproverScoring',
  props: {
    activeScoring: String,
  },
  data() {
    return {
      vendorText: 'Vendor',
    }
  },
  computed: {
    scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
    showTable() {
      return this.scoringVendorApproval?.length > 0
    },
    approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
    threeColumn() {
      return !!this.approvalGsit?.userId && !!this.approvalUkkp?.userId
    },
    colspan() {
      return this.threeColumn? '2' : '3'
    },
    colspanPrice() {
      return this.oneColumnPrice? '6' : this.threeColumnPrice? '2' : '3'
    },
    scoringPriceLogApproval() {
			return this.$store.state.negotiation.scoringPriceLogApproval
		},
    menuRfpNegotiationScoringPriceLogApproval() {
			return this.$store.getters['dashboard/menuRfpNegotiationScoringPriceLogApproval']
		},
    oneColumnPrice() {
      return !this.scoringPriceLogApproval?.ukkpId && !this.scoringPriceLogApproval?.gsitId
    },
    threeColumnPrice() {
      return !!this.scoringPriceLogApproval?.ukkpId && !!this.scoringPriceLogApproval?.gsitId
    },
    showScoringPriceLog() {
      return (this.scoringPriceLogApproval?.isSubmittedScoringPrice || this.scoringPriceLogApproval?.isRejected) && this.menuRfpNegotiationScoringPriceLogApproval?.canRead && this.activeScoring === this.vendorText
    }
  },
  methods: {
    textStatusApproved(status) {
      if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else {
        return 'text-error'
      }

    },
    
  }
}
</script>