<template>
  <div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <table class="w-full" aria-describedby="approverLog">
        <template v-if="rfpLogApproval?.reviewerGsitName || rfpLogApproval?.reviewerKpName || rfpLogApproval?.firstApproverDlogName">
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left p-2">
                <p class="text-sm font-medium text-gray">Reviewed By</p>
              </th>
            </tr>
            <tr class="bg-primary-lightest">
              <th v-if="rfpLogApproval?.reviewerGsitName" scope="col" :colspan="colspanReviewer" class="p-2 border-t border-gray-light" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th v-if="rfpLogApproval?.reviewerKpName" scope="col" :colspan="colspanReviewer" class="p-2 border-t border-gray-light" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3 border-l' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
              <th scope="col" class="p-2 border-t border-gray-light" :colspan="colspanReviewer" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3 border-l' : 'w-1/2 border-l'}`" >
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-if="rfpLogApproval?.reviewerGsitName" :colspan="colspanReviewer" class="p-2 border-gray-light border-b" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.reviewerGsitName }}</p>
                <p v-if="rfpLogApproval?.reviewerGsitStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.reviewerGsitStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.reviewerGsitApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.reviewerGsitApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.reviewerGsitApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.reviewerGsitApproved?.split('|')[1] }}</span> 
                </p>
              </td>
              <td v-if="rfpLogApproval?.reviewerKpName" :colspan="colspanReviewer" class="p-2 border-gray-light border-b" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3 border-l' : 'w-1/2'}`" >
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.reviewerKpName }}</p>
                <p v-if="rfpLogApproval?.reviewerKpStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.reviewerKpStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.reviewerKpApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.reviewerKpApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.reviewerKpApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.reviewerKpApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-gray-light border-b" :colspan="colspanReviewer" :class="`${oneColumnReviewer? 'w-full' : threeColumnsReviewer? 'w-1/3 border-l' : 'w-1/2 border-l'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.firstApproverDlogName }}</p>
                <p v-if="rfpLogApproval?.firstApproverDlogStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.firstApproverDlogStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.firstApproverDlogApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.firstApproverDlogApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.firstApproverDlogApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span> 
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.firstApproverDlogApproved?.split('|')[1] }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </template>
        <template>
          <thead>
            <tr>
              <th scope="col" colspan="6" class="text-left p-2">
                <p class="text-sm font-medium text-gray">Approved By</p>
              </th>
            </tr>
            <tr class="bg-primary-lightest">
              <th v-if="rfpLogApproval?.directApproverGsitId" scope="col" :colspan="colspanApprover" class="p-2 border-t border-gray-light" :class="`${threeColumnsApprover? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium text-left text-gray">GSIT</p>
              </th>
              <th v-if="rfpLogApproval?.directApproverKpId" scope="col" :colspan="colspanApprover" class="p-2 border-t border-gray-light" :class="`${threeColumnsApprover? 'w-1/3 border-l' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">UKKP</p>
              </th>
              <th scope="col" class="p-2 border-t border-l border-gray-light" :colspan="colspanApprover" :class="`${threeColumnsApprover? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium text-left text-gray">DLOG</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td v-if="rfpLogApproval?.directApproverGsitId" class="p-2 border-gray-light" :colspan="colspanApprover" :class="`${threeColumnsApprover? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.directApproverGsitName }}</p>
                <p v-if="rfpLogApproval?.directApproverGsitStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.directApproverGsitStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.directApproverGsitApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.directApproverGsitApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.directApproverGsitApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.directApproverGsitApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td v-if="rfpLogApproval?.directApproverKpId" class="p-2 border-gray-light" :colspan="colspanApprover" :class="`${threeColumnsApprover? 'w-1/3 border-l' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.directApproverKpName }}</p>
                <p v-if="rfpLogApproval?.directApproverKpStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.directApproverKpStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.directApproverKpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.directApproverKpApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.directApproverKpApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.directApproverKpApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-gray-light border-l" :colspan="colspanApprover" :class="`${threeColumnsApprover? 'w-1/3' : 'w-1/2'}`" >
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ rfpLogApproval?.secondApproverDlogName }}</p>
                <p v-if="rfpLogApproval?.secondApproverDlogStatus?.toLowerCase() === 'on review'" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark capitalize">{{ rfpLogApproval?.secondApproverDlogStatus?.toLowerCase() }}</p>
                <p v-if="rfpLogApproval?.secondApproverDlogApproved"
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="textStatusApproved(rfpLogApproval?.secondApproverDlogApproved?.split('|')[0]?.toLowerCase())" class="capitalize">{{ rfpLogApproval?.secondApproverDlogApproved?.split('|')[0]?.toLowerCase() }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ rfpLogApproval?.secondApproverDlogApproved?.split('|')[1] }}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableApproverDetail',
  data() {
    return {
      ukkp: true
    }
  },
  computed: {
    rfpLogApproval() {
      return this.$store.state.projectDetail.rfpLogApproval
    },
    threeColumnsReviewer() {
      return !!this.rfpLogApproval?.reviewerGsitId && !!this.rfpLogApproval?.reviewerKpId
    },
    oneColumnReviewer() {
      return !this.rfpLogApproval?.reviewerGsitId && !this.rfpLogApproval?.reviewerKpId
    },
    threeColumnsApprover() {
      return !!this.rfpLogApproval?.directApproverGsitId && !!this.rfpLogApproval?.directApproverKpId
    },
    colspanReviewer() {
      return this.oneColumnReviewer? '6' : this.threeColumnsReviewer? '2' : '3'
    },
    colspanApprover() {
      return this.threeColumnsApprover? '2' : '3'
    }
  },
  methods: {
    textStatusApproved(status) {
      if (status?.toLowerCase().includes('approved')) {
        return 'text-success'
      } else {
        return 'text-error'
      }

    },
  }
}
</script>