<template>
  <div class="flex w-full h-full rounded-md item-center" :class="grayColor? 'bg-gray-lightest px-4 py-3 min-h-tiptap' : ''" v-if="editor">
    <editor-content :editor="editor" class="w-full text-gray-dark" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

export default {
  name: "TiptapView",
  props: {
    value: {
      type: String,
      default: '',
    },
    grayColor: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editor: null,
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          }
        }),
        Underline,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      onUpdate: () => {
        // HTML
        const content = this.editor.isEmpty ? '' : this.editor.getHTML()
        this.$emit('input', content)
      },
      editorProps: {},
      editable: false,
      autoFocus: null,
      useBuiltInExtensions: true,
      disableInputRules: false,
      disablePasteRules: false,
      dropCursor: {},
      parseOptions: {},
      injectCSS: true,
      onInit: () => ({}),
      onTransaction: () => ({}),
      onFocus: () => ({}),
      onBlur: () => ({}),
      onPaste: () => ({}),
      onDrop: () => ({}),
    })
  },
  beforeUnmount() {
    this.editor.destroy()
  },
  components: {
    EditorContent,
  },
}
</script>

<style scoped>
.min-h-tiptap {
  min-height: 5rem;
}
</style>