<template>
	<div :class="projectDetailContainerWidth < 640 ? 'w-full' : ''">
		<ButtonGista v-if="menuScoringDecision?.canUpdate && completeScoring && someEnableScoringDecision"
			color="success"
			:customClass="projectDetailContainerWidth < 640 ? 'w-full' : ''"
			:disabled="proposalInformation?.isCanceled"
			@click="changeIsOpen(true)">Submit Decision</ButtonGista>
		<ModalConfirm 
				title="Apakah Anda Yakin?" 
				:message="'Pastikan seluruh informasi sudah sesuai, \ninformasi tidak dapat diubah setelah di kirim.'" 
				icon="submitted" 
				textButton="Submit Decision" 
				:isOpen="isOpen" 
				:isBackButton="true"
				@changeIsOpen="changeIsOpen($event)"
				:hideClose="true" 
				@click="click"
				colorButton="success"></ModalConfirm>
	</div>

</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'
export default {
	name: "ModalSubmitDecision",
	props: ['completeScoring', 'someEnableScoringDecision'],
	data() {
		return {
			isOpen: false
		}
	},
	computed: {
		//menu scoring decision
		menuScoringDecision() {
			return this.$store.getters['dashboard/menuScoringDecision']
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	methods: {
		click() {
			this.isOpen = false
			this.$emit("click")
		},
		closeModal() {
			this.isOpen = false
		},
		changeIsOpen(val) {
			this.isOpen = val
		}
	},
	components: {
		ModalConfirm,
	}
}
</script>