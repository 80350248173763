<template>
	<div class="w-full">
		<div v-if="!someEnableScoringDecision || !menuScoringEscrow?.canUpdate" class="mb-6">
			<div class="flex" v-if="someEnableScoringDecision && !menuScoringEscrow?.canUpdate">
				<span class="icon-info flex items-center text-warning-darkest text-lg cursor-pointer text-gray hover:text-gray-dark"></span>
				<p class="font-medium text-warning-darkest pl-3.5">Proses Scoring Escrow sedang dilaksanakan oleh OPR GSIT/KP</p>
			</div>
		</div>
		<div v-if="!loading" class="overflow-x-auto">
			<div class="w-full overflow-x-auto min-width-custom">
				<table class="w-full h-px text-sm" id="scoringEscrowTable" aria-describedby="scoringEscrow">
					<thead>
						<tr class="font-semibold bg-white text-gray-darkest">
							<th scope="col" class="sticky left-0 p-0 font-semibold text-left bg-white "><p class="w-72 p-3.5 break-words">Risk Factor</p></th>
							<th scope="col" class="sticky w-20 p-0 bg-white left-72"><p class="flex items-center justify-center p-3.5 h-full font-semibold border-r-2 border-gray-light">Bobot</p></th>
							<th v-for="(n, i) in scoringEscrow"
								:key="i"
								scope="col"
								class="font-semibold truncate min-w-28">
								<p class="p-3.5">{{ n.vendorName }}</p></th>
						</tr>
					</thead>
					<tbody>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  break-words w-72 p-3.5"><p>{{ scoringEscrowRiskFactor[0]?.name }}</p></td>
							<td class="sticky w-20 p-0 left-72" ><p class="flex items-center justify-center p-3.5 h-full border-r-2 border-gray-light">{{ scoringEscrowRiskFactor[0]?.bobot }}%</p></td>
							<td v-for="(n, i) in scoringEscrow"
								:key="i"
								class=" p-3.5">
								<div>
									
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left border-b border-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('criticalApplication')"></span>
								{{ scoringEscrowRiskFactorPoint[0]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[0]?.riskFactorPoints[0]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[0]?.riskFactorPoints[0]?.skalaNilai)"
										:id="`criteria${i}`"
										@change="updateValueScoring($event, 0, 0, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary-lightest text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-primary-lightest w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('applicationComplexity')"></span>
								{{ scoringEscrowRiskFactorPoint[1]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-primary-lightest left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[0]?.riskFactorPoints[1]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[0]?.riskFactorPoints[1]?.skalaNilai)"
										:id="`application${i}`"
										@change="updateValueScoring($event, 0, 1, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 w-72 break-words">
								{{ scoringEscrowRiskFactor[1]?.name }}
							</td>
							<td class="sticky w-20 p-0 left-72" ><p class="flex items-center justify-center p-3.5 h-full border-r-2 border-gray-light">{{ scoringEscrowRiskFactor[1]?.bobot }}%</p></td>
							<td v-for="(n, i) in scoringEscrow"
								:key="i"
								class=" p-3.5">
								<div>
									
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('userImpact')"></span>
								{{ scoringEscrowRiskFactorPoint[2]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[1]?.riskFactorPoints[0]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[1]?.riskFactorPoints[0]?.skalaNilai)"
										:id="`user${i}`"
										@change="updateValueScoring($event, 1, 0, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 w-72 break-words">
								{{ scoringEscrowRiskFactor[2]?.name }}
							</td>
							<td class="sticky w-20 p-0 left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow"
								:key="i"
								class="p-3.5 ">
								<div>
									
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('totalCost')"></span>
								{{ scoringEscrowRiskFactorPoint[3]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[2]?.riskFactorPoints[0]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[2]?.riskFactorPoints[0]?.skalaNilai)"
										:id="`totalCost${i}`"
										@change="updateValueScoring($event, 2, 0, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 w-72 break-words">
								{{ scoringEscrowRiskFactor[3]?.name }}
							</td>
							<td class="sticky w-20 p-0 left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow"
								:key="i"
								class=" p-3.5">
								<div>
									
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left border-b border-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('availabilityOfSubstituteProducts')"></span>
								{{ scoringEscrowRiskFactorPoint[4]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[3]?.riskFactorPoints[0]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[3]?.riskFactorPoints[0]?.skalaNilai)"
										:id="`availability${i}`"
										@change="updateValueScoring($event, 3, 0, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary-lightest text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-primary-lightest w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('rebuildProducts')"></span>
								{{ scoringEscrowRiskFactorPoint[5]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-primary-lightest left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[3]?.riskFactorPoints[1]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[3]?.riskFactorPoints[1]?.skalaNilai)"
										:id="`rebuild${i}`"
										@change="updateValueScoring($event, 3, 1, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 w-72 break-words">
								{{ scoringEscrowRiskFactor[4]?.name }}
							</td>
							<td class="sticky w-20 p-0 left-72" >
								<p class="flex items-center justify-center p-3.5 h-full border-r-2 border-gray-light">{{ scoringEscrowRiskFactor[4]?.bobot }}%</p>
							</td>
							<td v-for="(n, i) in scoringEscrow"
								:key="i"
								class=" p-3.5">
								<div>
									
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left border-b border-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('vendorServiceLongevity')"></span>
								{{ scoringEscrowRiskFactorPoint[6]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[4]?.riskFactorPoints[0]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[4]?.riskFactorPoints[0]?.skalaNilai)"
										:id="`vendorService${i}`"
										@change="updateValueScoring($event, 4, 0, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left border-b bg-primary-lightest border-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-primary-lightest w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('vendorPortofolio')"></span>
								{{ scoringEscrowRiskFactorPoint[7]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-primary-lightest left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[4]?.riskFactorPoints[1]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[4]?.riskFactorPoints[1]?.skalaNilai)"
										:id="`vendorPortofolio${i}`"
										@change="updateValueScoring($event, 4, 1, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-white w-72 break-words">
								<span class="cursor-pointer icon-help-circle"
									@click="showInfo('affiliateWithBCA')"></span>
								{{ scoringEscrowRiskFactorPoint[8]?.name }}
							</td>
							<td class="sticky w-20 p-0 bg-white left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div v-if="!isEdit || !n.isEnableScoringEscrow" class="flex justify-center">
									{{n?.riskFactors[4]?.riskFactorPoints[2]?.scoreDisplay || '-'}}
								</div>
								<div v-else-if="n.isEnableScoringEscrow" class="flex justify-center">
									<radio-button-escrow :modelValue="String(n?.riskFactors[4]?.riskFactorPoints[2]?.skalaNilai)"
										:id="`affiliate${i}`"
										@change="updateValueScoring($event, 4, 2, i)"></radio-button-escrow>
								</div>
							</td>
						</tr>
						<tr class="font-medium text-left bg-primary text-gray-darkest">
							<td class="sticky left-0  p-3.5 bg-primary w-72 break-words">
								Total Nilai
							</td>
							<td class="sticky w-20 p-0 bg-primary left-72" ><p class="flex items-center p-3.5 h-full border-r-2 border-gray-light"></p></td>
							<td v-for="(n, i) in scoringEscrow" :key="i" class="p-3.5 ">
								<div class="flex justify-center">
									{{totalSum(i)}}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<ScoringEscrowLoading v-else/>

		<Card class="mt-6">
			<div class="overflow-x-auto">
				<div class="lg:min-width-custom">
					<div class="border-b border-gray pb-2.5" :class="projectDetailContainerWidth > 1024 ? 'truncate' : ''">
						<h5 class="text-lg text-gray-dark">Keterangan hasil pengukuran urgensi pembuatan Escrow Agreement</h5>
					</div>
					<div class="flex border-b border-gray w-full py-2.5" :class="projectDetailContainerWidth > 640 ? 'flex-row gap-0' : 'flex-col gap-4'">
						<div class="flex" :class="projectDetailContainerWidth > 640 ? 'w-1/3' : 'w-full'">
							<div class="w-1/2">
								<h5 class="text-sm text-gray-light">Final Score</h5>
								<p class="text-gray">&#8805; 50 Poin</p>
							</div>
							<div class="w-1/2">
								<h5 class="text-sm text-gray-light">Kategori</h5>
								<p class="text-gray">Low Risk</p>
							</div>
						</div>
						<div :class="projectDetailContainerWidth > 640 ? 'w-2/3' : 'w-full'">
							<h5 class="text-sm text-gray-light">Tindak Lanjut</h5>
							<p class="text-gray">Tidak perlu mempertimbangkan pembuatan Escrow Agreement dalam pengadaan barang dan/atau jasa TI</p>
						</div>
					</div>
					<div class="flex w-full py-2.5" :class="projectDetailContainerWidth > 640 ? 'flex-row gap-0' : 'flex-col gap-4'">
						<div class="flex" :class="projectDetailContainerWidth > 640 ? 'w-1/3' : 'w-full'">
							<div class="w-1/2">
								<h5 class="text-sm text-gray-light">Final Score</h5>
								<p class="text-gray">&#60; 50 Poin</p>
							</div>
							<div class="w-1/2">
								<h5 class="text-sm text-gray-light">Kategori</h5>
								<p class="text-gray">High Risk</p>
							</div>
						</div>
						<div :class="projectDetailContainerWidth > 640 ? 'w-2/3' : 'w-full'">
							<h5 class="text-sm text-gray-light">Tindak Lanjut</h5>
							<p class="text-gray">Perlu mempertimbangkan pembuatan Escrow Agreement dalam pengadaan barang dan/atau jasa TI</p>
						</div>
					</div>
				</div>
			</div>
		</Card>
		<ValidationObserver ref="scoringEscrow">
			<div class="w-full mt-6">
				<ValidationProvider name="Pertimbangan"
					rules="max:500"
					v-slot="{ errors }">
					<TextareaGista label="Pertimbangan pembuatan Escrow Agreement"
						placeholder="Leave a comment here"
						v-model="scoringEscrowNotes"
						:error="!!errors[0]"
						:disabled="!isEdit">
						<template #message>
							<p>{{ errors[0] }}</p>
						</template>
					</TextareaGista>
				</ValidationProvider>
			</div>
			<template v-if="proposalInformation.isSoftware && totalApprovedVendorProposal === 1">
				<div class="flex mt-6 mb-3.5 cursor-pointer"
					@click="changeCollapse">
					<div class="flex flex-auto">
						<span class="icon-file text-xl text-gray-dark font-semibold mr-6"></span>
						<h4 class="text-base text-gray-dark font-semibold">ATTACHMENTS</h4>
					</div>
					<div class="flex-none">
						<p :class="!collapse? 'rotate-180': 'rotate-0'"
							class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
					</div>
				</div>
				<div v-if="!collapse">
					<div class="mb-3.5" v-for="n,i in attachmentsNonDeleted" :key="i">
						<div>
							<p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
						</div>
						<div class="flex items-center justify-between border rounded-lg h-14 border-primary">
							<div class="text-base font-medium text-gray p-4 truncate">{{ n.fileName }}</div>
							<div class="p-4 flex items-center">
								<span v-if="isEdit" class="cursor-pointer text-2xl icon-trash-2 text-error hover:text-error-dark" @click="clickRemoveFileAttachment(n)"></span>
								<span v-else class="cursor-pointer text-2xl icon-eye text-primary hover:text-primary-dark" @click="openFile(n)" ></span>
							</div>
						</div>
					</div>
					<div v-if="isEdit">
						<div class="flex flex-wrap items-center">
							<div class="flex" v-if="maxAttachment">
								<span class="mr-2 icon-file-plus flex items-center text-secondary"></span>
								<input type="file"
									id="upload-file-attachment"
									ref="scoring"
									hidden
									@change="addAttachment($event, 'scoring')" />
								<label for="upload-file-attachment"
									class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
									Add
								</label>
							</div>
							<p class="mr-3.5" :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Max Attachment: 10 </p>
							<p :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Supported file: .pdf .jpg .jpeg .png, max file size: 10 MB</p>
						</div>
					</div>
				</div>
			</template>
			<div v-if="showButtonScoring" class="flex mt-6"
				:class="`${isEdit ? 'justify-between' : 'justify-end'} ${projectDetailContainerWidth < 768 ? 'flex-col' : ''}`">
				<div v-if="isEdit"
					class="flex items-center">
					<span class="pr-3 mr-3 text-xl icon-alert-circle text-warning"></span>
					<p class="mr-3 text-warning">Anda harus mengisi semua skala nilai setiap vendor untuk menyimpan.</p>
				</div>
				<div v-if="menuScoringEscrow?.canUpdate"
					class="flex justify-end">
					<button-gista v-if="!isEdit"
						:type="inputScoringOrEditScoring? 'secondary': 'primary'"
						color="secondary"
						:customClass="projectDetailContainerWidth < 640 ? 'w-full' : ''"
						:disabled="proposalInformation?.isCanceled"
						@click="clickEditInput">
						{{ inputScoringOrEditScoring? 'Edit' : 'Input'}}
					</button-gista>
					<div v-if="isEdit"
						class="flex" :class="projectDetailContainerWidth < 640 ? 'flex-col w-full gap-3 mt-3' : ''">
						<button-gista type="tertiary"
							color="gray"
							:customClass="`${projectDetailContainerWidth < 640 ? 'w-full' : ''} border mr-6`"
							:disabled="proposalInformation?.isCanceled"
							@click="clickDiscard">
							Discard
						</button-gista>
						<button-gista type="primary"
							:disabled="proposalInformation?.isCanceled"
							@click="clickSave">
							Save
						</button-gista>
					</div>
				</div>
			</div>
		</ValidationObserver>
		<InfoEscrow :modalInfo="modalInfo" />
	</div>
</template>
<script>
import RadioButtonEscrow from './editInput/radioButtonEscrow.vue';
import Card from '@/core/components/custom/Card.vue'
import InfoEscrow from '@/core/components/rfp/info-scoring-escrow/InfoEscrow';
import { SCORING_DECISION_ELIMINATED } from '@/core/constant/qualification.js'
import { MESSAGE_SUCCESS_SCORING_ESCROW } from '@/core/constant/successMessage'
import ScoringEscrowLoading from '@/core/components/rfp/loading/ScoringEscrowLoading.vue';
export default {
	name: "ScoringEscrow",
	data() {
		return {
			loading: false,
			isEdit: false,
			modalInfo: {
				criticalApplication: { value: false, title: 'Critical Application', description: 'Aplikasi memiliki fungsi utama beroperasinya proses bisnis (mengacu pada dokumen Map Critical Business Function (CBF) & Recovery Time Objectives (RTO)) ', nilai: ['Very High CBF', 'Moderately to High CBF', 'Less CBF'] },
				applicationComplexity: { value: false, title: 'Application Complexity', description: 'Aplikasi memiliki tingkat kompleksitas yang tinggi \nContoh: \n- Jumlah interface/integrasi dengan aplikasi lain \n- Jumlah validasi (UI, Services) \n- Jumlah modul/fungsi aplikasi ', nilai: ['High', 'Medium', 'Low'] },
				userImpact: { value: false, title: 'User Impact', description: 'Jumlah pengguna yang terdampak ', nilai: ['High (Internal & External)', 'Medium (Internal & Implementasi Nasional)', 'Low (Internal & Implementasi Non Nasional) '] },
				totalCost: { value: false, title: 'Total Cost', description: 'Total biaya yang dikeluarkan pada saat inisiasi, operasional, dan pengembangan \nContoh: \n- License Fee \n- Installation Fee \n- Operational Fee ', nilai: ['High Budget (>10 Milyar)', 'Medium Budget (5-10 Milyar)', 'Low Budget (≤5 Milyar)'] },
				availabilityOfSubstituteProducts: { value: false, title: 'Availability Of Substitute Products', description: 'Ketersediaan produk yang memiliki fungsi serupa sesuai kebutuhan oleh vendor yang sama/berbeda', nilai: ['Tidak tersedia', 'Tersedia dan belum siap dipakai', 'Tersediadan siap dipakai'] },
				rebuildProducts: { value: false, title: 'Rebuild Products', description: 'Tingkat lama waktu yang diperlukan untuk membangun ulang aplikasi (termasuk perhitungan waktu negosiasi perjanjian lisensi dengan vendor baru apabila solusi pembelian aplikasi dilakukan) ', nilai: ['Sangat lama (>3 Tahun)', 'Cukup lama (1-3 Tahun)', 'Cepat (<1 Tahun)'] },
				vendorServiceLongevity: { value: false, title: 'Vendor Service Longevity', description: 'Lama waktu perusahaan vendor dalam menyediakan jasa layanan', nilai: ['Baru (1-5 Tahun)', 'Cukup lama (6-10 Tahun)', 'Lama (>10 Tahun)'] },
				vendorPortofolio: { value: false, title: 'Vendor Portofolio', description: 'Wilayah dan bidang institusi klien dimana vendor melakukan suplai barang/jasa', nilai: ['Non Financial Institution – Asia Pacific', 'Asia Pacific', 'Financial Institution – Worldwide'] },
				affiliateWithBCA: { value: false, title: 'Affiliate With BCA', description: 'Vendor memiliki hubungan afiliasi dengan BCA \n- Controlling: memiliki kepemilikian saham dan kendali atas perusahaan tersebut \n- Affiliate & Non Controlling: memiliki kepemilikan saham tapi tidak memiliki kendali \n- Non Affiliate: Tidak memiliki kepemilikan saham dan kendali', nilai: ['Non Affiliate', 'Affiliate & Non Controlling (Saham ≤50%)', 'Controlling (Saham >50%)'] },
			},
			collapse: false,
			errorFileType: false,
		}
	},
	computed: {
		vendorCandidate() {
			return this.$store.state.projectDetail.vendorCandidate.filter(e => e.isActive)
		},
		scoringEscrow: {
			get() {
				return this.$store.state.projectDetail.scoringEscrow?.candidates
			},
			set(value) {
				this.$store.commit('projectDetail/SET_SCORING_ESCROW', value)
			}
		},
		scoringEscrowNotes: {
			get() {
				return this.$store.state.projectDetail.scoringEscrow?.scoringEscrowNotes
			},
			set(value) {
				this.$store.commit('projectDetail/SET_SCORING_ESCROW', { ...this.$store.state.projectDetail.scoringEscrow, scoringEscrowNotes: value })
			}
		},
		qualificationsDecisionEliminated() {
			return this.$store.state.qualification.qualifications.filter(e => e.statusVendor === SCORING_DECISION_ELIMINATED)
		},
		scoringEscrowRiskFactor() {
			return this.$store.state.projectDetail.scoringEscrowRiskFactor
		},
		scoringEscrowRiskFactorPoint() {
			return this.$store.state.projectDetail.scoringEscrowRiskFactorPoint
		},
		projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		showButtonScoring() {
			return this.scoringVendorDecision.some(e => !e.isSubmittedDecision || e.isRevisionScoringTechEscrow) && !this.proposalInformation?.isAnyVendorThatHaveNotBeenQualified
		},
		inputScoringOrEditScoring() {
			return this.scoringEscrow?.some(e => e.riskFactors?.some(el => el.riskFactorPoints?.some(ele => ele.skalaNilai)))
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		//menu scoring escrow
		menuScoringEscrow() {
			return this.$store.getters['dashboard/menuScoringEscrow']
		},
    projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		attachments: {
			get() {
				return this.$store.state.projectDetail.attachments
			},
			set(value) {
        this.$store.commit('projectDetail/SET_ATTACHMENTS', value)
			}
		},
		attachmentsNonDeleted() {
			return this.attachments.filter(e => !e.isDeleted)
		},
		maxAttachment() {
      return this.attachmentsNonDeleted.length < 10
    },
		totalApprovedVendorProposal() {
			return this.$store.state.qualification.totalApprovedVendorProposal
		},
		scoringVendorDecision() {
			return this.$store.state.projectDetail.scoringVendorDecision
    },
		someEnableScoringDecision() {
			return this.scoringVendorDecision.some(e => e.isEnableScoringDecision || e.isRevisionScoringTechEscrow) && !this.proposalInformation?.isAnyVendorThatHaveNotBeenQualified
		},
		userDetail() {
			return this.$store.state.dashboard.userProfile;
		},
		scoringVendorApproval() {
      return this.$store.state.projectDetail.scoringVendorApproval
    },
		approvalGsit() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'GSIT')
    },
    approvalUkkp() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'UKKP')
    },
    approvalDlog() {
      return this.scoringVendorApproval?.find(e => e.unitKerja === 'ITP')
    },
	},
	methods: {
		clickEditInput() {
			this.isEdit = true
		},
		async clickDiscard() {
			this.isEdit = false
			await this.deleteFileNotSaved()
			this.$store.dispatch('projectDetail/getScoringVendorEscrow', this.$route.params.projectId)
		 	this.$store.dispatch('projectDetail/getScoringAttachments', this.$route.params.projectId)
		},
		async clickSave() {
			const success = await this.$refs.scoringEscrow.validate()
			if (!success) {
				this.modal.modalLoading = false
				return
			}
			this.isEdit = false
			try {
				//show loading
				this.modal.modalLoading = true
				const response = await this.$store.dispatch('projectDetail/putScoringVendorEscrow', { rfpId: this.$route.params.projectId, scoringEscrowNotes: this.scoringEscrowNotes })
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					//show modal success edit scoring escrow
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SCORING_ESCROW }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false
				//show modal error edit scoring escrow
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
			this.$store.dispatch('projectDetail/getScoringVendorEscrow', this.$route.params.projectId)

		},
		updateValueScoring(e, rf, rfp, index) {
			this.scoringEscrow[index].riskFactors[rf].riskFactorPoints[rfp].skalaNilai = +e
		},
		totalSum(index) {
			const tigaPuluh = +(this.scoringEscrow[index].riskFactors[0].riskFactorPoints.reduce((a,b) => +a + +b.skalaNilai, 0)*30/6).toFixed(1)
			const duaPuluh1 = this.scoringEscrow[index].riskFactors[1].riskFactorPoints[0].skalaNilai
			const duaPuluh2 = this.scoringEscrow[index].riskFactors[2].riskFactorPoints[0].skalaNilai
			const duaPuluh3 = this.scoringEscrow[index].riskFactors[3].riskFactorPoints.reduce((a,b) => +a + +b.skalaNilai, 0)
			const duaPuluh = +((duaPuluh1+duaPuluh2+duaPuluh3)*20/12).toFixed(1)
			const limaPuluh = +(this.scoringEscrow[index].riskFactors[4].riskFactorPoints.reduce((a,b) => +a + +b.skalaNilai, 0)*50/9).toFixed(1)
			return (tigaPuluh + duaPuluh + limaPuluh).toFixed(1)
		},
		showInfo(category) {
			this.modalInfo[category].value = true
		},
		criteria1(value) {
			return String(value/10)
		},
		criteria2(value) {
			return String(Math.round(value/20 * 3))
		},
		criteria3(value) {
			return String(Math.round(value/50 * 3))
		},
		resizeWidthTable() {
			this.tdWidth1 = document.querySelector('.tdWidthClass1')?.offsetWidth
		},
		changeCollapse() {
			this.collapse = !this.collapse
		},
		async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf','image/jpeg','image/png'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
				try {
					this.modal.modalLoading = true
					this.errorFileType = false
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					formData.append('projectType', 'RFP')
					const response = await this.$store.dispatch('file/uploadFile', formData)
					if (response?.status < 300 && response?.status >= 200) {
						console.log(response?.headers, 'response headers upload file');
						const data = response.data.result
						const fileAfterUpload = {
							fileName: data.fileName,
							filePath: data.filePath,
							documentNo: data.documentNo,
							fileRef: fileRef,
							isDeleted: false,
							isSubmitted: false,
							attachmentId: 0
						}
						this.attachments = [ ...this.attachments, fileAfterUpload ]
						this.errorFileType = false
						this.modal.modalLoading = false
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
        } catch (error) {
					this.modal.modalLoading = false
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
        
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
		openFile(n) {
			const { fileName, documentNo } = n
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
		async clickRemoveFileAttachment(n){
      try {
				this.modal.modalLoading = true
				const fileAttachment = this.attachments.find(e => e.filePath === n.filePath)
        if (fileAttachment?.attachmentId > 0 ) {
          this.attachments = this.attachments.map(e => (e.filePath === fileAttachment.filePath? ({ ...e, isDeleted: true }): e))
        } else {
          const response = await this.$store.dispatch('file/deleteFile', fileAttachment.documentNo || fileAttachment.fileName)
          if (response?.status < 300 && response?.status >= 200) {
						this.attachments = this.attachments.filter(e => e.filePath !== n.filePath)
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
        }
				this.modal.modalLoading = false
      } catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
		async deleteFileNotSaved() {
			try {
				const attachmentsFiltered = this.attachments.filter(e => e.attachmentId === 0)
				for (const attachment of attachmentsFiltered) {
					const response = await this.$store.dispatch('file/deleteFile', attachment.documentNo || attachment.fileName)
					if (response?.status >= 300) {
						this.$store.dispatch('modal/throwError', { response })
					}
				}
				this.modal.modalLoading = false
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		async reviewApproval() {
			if (this.approvalGsit?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalGsit.needReviewEscrowScoring && this.userDetail.username.toLowerCase() === this.approvalGsit?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'ESCROW' }, rfpId: this.$route.params.projectId })
      } else if (this.approvalUkkp?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalUkkp.needReviewEscrowScoring && this.userDetail.username.toLowerCase() === this.approvalUkkp?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'ESCROW' }, rfpId: this.$route.params.projectId })
      } else if (this.approvalDlog?.approvalStatusLocale?.toLowerCase() === 'on review' && this.approvalDlog.needReviewEscrowScoring && this.userDetail.username.toLowerCase() === this.approvalDlog?.userId?.toLowerCase()) {
				await this.$store.dispatch('projectDetail/putReviewScoringApproval', { data: { username: this.userDetail.username.toLowerCase(), scoringType: 'ESCROW' }, rfpId: this.$route.params.projectId })
			}
			this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
		}
	},
	async mounted() {
		this.loading = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
		console.log(res, res?.data.code, 'get access');
    if (res?.data.code !== 200) return
    const r1 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		const r2 = this.$store.dispatch('projectDetail/getScoringEscrowRiskFactor')
		const r3 = this.$store.dispatch('projectDetail/getScoringEscrowRiskFactorPoint')
		const r4 = this.$store.dispatch('projectDetail/getScoringVendorEscrow', this.$route.params.projectId)
		const r5 = this.$store.dispatch('projectDetail/getScoringAttachments', this.$route.params.projectId)
    const r6 = this.$store.dispatch('projectDetail/getScoringVendorApproval', { rfpId: this.$route.params.projectId })
    const r7 = this.$store.dispatch('projectDetail/getScoringVendorDecision', this.$route.params.projectId)
		const [res1, res2, res3, res4, res5, res6 ,res7] = await Promise.all([r1, r2, r3, r4, r5, r6, r7])
		if (res1?.status === 200 && res2?.status === 200 && res3?.status === 200 && res4?.status === 200 && res5?.status === 200 && res6?.status === 200 && res7?.status === 200 ) {
			this.loading = false
			this.reviewApproval()
		}
	},

	destroyed() {
		if (this.isEdit) {
			this.clickDiscard()
		}
	},
	components: {
		RadioButtonEscrow,
		Card,
		InfoEscrow,
		ScoringEscrowLoading,
	}
}
</script>

<style scoped>
.min-width-custom {
	min-width: 40rem;
}
.min-w-28 {
	min-width: 7rem;
}
</style>